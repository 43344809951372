import webLoader from '@sportheroes/oly-ds/utils/webLoader';

window.__APP_LOADED__ = false;

const loader = webLoader();

window.__ON_APP_READY__ = () => {
  if (
    window.__APP_LOADED__ === false &&
    window.__APP_SW_INSTALLED__ &&
    window.__APP_SW_UPDATING__ !== true
  ) {
    window.__APP_LOADED__ = true;

    loader.remove();
  } else {
    setTimeout(window.__ON_APP_READY__, 500);
  }
};
